// Require and export all files in this directory and sub-directories
module.exports['address-book'] = require('./address-book.html')
module.exports.admin = require('./admin.html')
module.exports.alert = require('./alert.html')
module.exports.archive = require('./archive.html')
module.exports['arrow-another'] = require('./arrow-another.html')
module.exports['arrow-circle-left'] = require('./arrow-circle-left.html')
module.exports['arrow-down'] = require('./arrow-down.html')
module.exports['arrow-left'] = require('./arrow-left.html')
module.exports['arrow-return'] = require('./arrow-return.html')
module.exports['arrow-right'] = require('./arrow-right.html')
module.exports['arrow-select'] = require('./arrow-select.html')
module.exports['arrow-shaft-down'] = require('./arrow-shaft-down.html')
module.exports['arrow-shaft-up'] = require('./arrow-shaft-up.html')
module.exports['arrow-top'] = require('./arrow-top.html')
module.exports['arrow-up'] = require('./arrow-up.html')
module.exports['arrows-circling'] = require('./arrows-circling.html')
module.exports.attachment = require('./attachment.html')
module.exports['attachment-alternative'] = require('./attachment-alternative.html')
module.exports.autosave = require('./autosave.html')
module.exports['bar-chart'] = require('./bar-chart.html')
module.exports.barcode = require('./barcode.html')
module.exports.bold = require('./bold.html')
module.exports['calendar-check'] = require('./calendar-check.html')
module.exports.calendar = require('./calendar.html')
module.exports.camera = require('./camera.html')
module.exports.cancel = require('./cancel.html')
module.exports.chat = require('./chat.html')
module.exports.check = require('./check.html')
module.exports['circle-cross'] = require('./circle-cross.html')
module.exports['cliniko-logo-avatar'] = require('./cliniko-logo-avatar.html')
module.exports['cliniko-logo-avatar-inverted'] = require('./cliniko-logo-avatar-inverted.html')
module.exports['cliniko-logo-c'] = require('./cliniko-logo-c.html')
module.exports['cliniko-logo-l'] = require('./cliniko-logo-l.html')
module.exports['cliniko-logo-i'] = require('./cliniko-logo-i.html')
module.exports['cliniko-logo-n'] = require('./cliniko-logo-n.html')
module.exports['cliniko-logo-k'] = require('./cliniko-logo-k.html')
module.exports['cliniko-logo-o'] = require('./cliniko-logo-o.html')
module.exports['cliniko-logo-words'] = require('./cliniko-logo-words.html')
module.exports.cog = require('./cog.html')
module.exports.comment = require('./comment.html')
module.exports.community = require('./community.html')
module.exports.config = require('./config.html')
module.exports.copy = require('./copy.html')
module.exports['credit-card'] = require('./credit-card.html')
module.exports['cross-circle-fill'] = require('./cross-circle-fill.html')
module.exports['cross-circle'] = require('./cross-circle.html')
module.exports.cross = require('./cross.html')
module.exports.dashboard = require('./dashboard.html')
module.exports.delete = require('./delete.html')
module.exports.document = require('./document.html')
module.exports.door = require('./door.html')
module.exports['download-doc'] = require('./download-doc.html')
module.exports.download = require('./download.html')
module.exports.edit = require('./edit.html')
module.exports.email = require('./email.html')
module.exports.envelope = require('./envelope.html')
module.exports['exit-full-screen'] = require('./exit-full-screen.html')
module.exports['external-link'] = require('./external-link.html')
module.exports.eye = require('./eye.html')
module.exports['full-screen'] = require('./full-screen.html')
module.exports.hamburger = require('./hamburger.html')
module.exports['hang-up'] = require('./hang-up.html')
module.exports.heart = require('./heart.html')
module.exports.history = require('./history.html')
module.exports.import = require('./import.html')
module.exports['indent-left'] = require('./indent-left.html')
module.exports['indent-right'] = require('./indent-right.html')
module.exports.info = require('./info.html')
module.exports.invoice = require('./invoice.html')
module.exports.italic = require('./italic.html')
module.exports['link-chain'] = require('./link-chain.html')
module.exports['link-chain-fill'] = require('./link-chain-fill.html')
module.exports['life-ring'] = require('./life-ring.html')
module.exports['main-nav-toggle'] = require('./main-nav-toggle.html')
module.exports['mega-cross'] = require('./mega-cross.html')
module.exports.merge = require('./merge.html')
module.exports.microphone = require('./microphone.html')
module.exports.minus = require('./minus.html')
module.exports.more = require('./more.html')
module.exports.ol = require('./ol.html')
module.exports['open-book'] = require('./open-book.html')
module.exports['paper-plane'] = require('./paper-plane.html')
module.exports.passkey = require('./passkey.html')
module.exports.payment = require('./payment.html')
module.exports['pencil-ruler'] = require('./pencil-ruler.html')
module.exports.pencil = require('./pencil.html')
module.exports.people = require('./people.html')
module.exports.person = require('./person.html')
module.exports['picture-in-picture'] = require('./picture-in-picture.html')
module.exports['pin-location'] = require('./pin-location.html')
module.exports.pin = require('./pin.html')
module.exports.play = require('./play.html')
module.exports['plus-circle'] = require('./plus-circle.html')
module.exports.plus = require('./plus.html')
module.exports['price-tag'] = require('./price-tag.html')
module.exports.print = require('./print.html')
module.exports['qr-code'] = require('./qr-code.html')
module.exports['question-circle'] = require('./question-circle.html')
module.exports['redesign-archive'] = require('./redesign-archive.html')
module.exports['redesign-cancel'] = require('./redesign-cancel.html')
module.exports['redesign-download'] = require('./redesign-download.html')
module.exports['redesign-duplicate'] = require('./redesign-duplicate.html')
module.exports['redesign-hamburger'] = require('./redesign-hamburger.html')
module.exports['redesign-import'] = require('./redesign-import.html')
module.exports['redesign-magnifying-glass'] = require('./redesign-magnifying-glass.html')
module.exports['redesign-pencil'] = require('./redesign-pencil.html')
module.exports['redesign-plus'] = require('./redesign-plus.html')
module.exports['redesign-tick'] = require('./redesign-tick.html')
module.exports['redesign-unarchive'] = require('./redesign-unarchive.html')
module.exports['redesign-x'] = require('./redesign-x.html')
module.exports.refresh = require('./refresh.html')
module.exports['sad-face'] = require('./sad-face.html')
module.exports.screen = require('./screen.html')
module.exports['screen-share'] = require('./screen-share.html')
module.exports.search = require('./search.html')
module.exports.secure = require('./secure.html')
module.exports['smiley-face'] = require('./smiley-face.html')
module.exports['speech-bubbles'] = require('./speech-bubbles.html')
module.exports['speech-bubble-dots'] = require('./speech-bubble-dots.html')
module.exports.stop = require('./stop.html')
module.exports.support = require('./support.html')
module.exports.telephone = require('./telephone.html')
module.exports.text = require('./text.html')
module.exports['text-size'] = require('./text-size.html')
module.exports.tick = require('./tick.html')
module.exports.time = require('./time.html')
module.exports['two-columns'] = require('./two-columns.html')
module.exports.ul = require('./ul.html')
module.exports.unarchive = require('./unarchive.html')
module.exports.underline = require('./underline.html')
module.exports.unsecure = require('./unsecure.html')
module.exports.upload = require('./upload.html')
module.exports.user = require('./user.html')

module.exports['appointments-1'] = require('./appointments/appointments-1.html')
module.exports['appointments-2'] = require('./appointments/appointments-2.html')
module.exports['appointments-3'] = require('./appointments/appointments-3.html')
module.exports['appointments-4'] = require('./appointments/appointments-4.html')
module.exports['appointments-5'] = require('./appointments/appointments-5.html')
module.exports['appointments-6'] = require('./appointments/appointments-6.html')
module.exports['appointments-7'] = require('./appointments/appointments-7.html')
module.exports['appointments-8'] = require('./appointments/appointments-8.html')
module.exports['appointments-9'] = require('./appointments/appointments-9.html')
module.exports['appointments-10'] = require('./appointments/appointments-10.html')
module.exports['appointments-11'] = require('./appointments/appointments-11.html')
module.exports['appointments-12'] = require('./appointments/appointments-12.html')
module.exports['appointments-13'] = require('./appointments/appointments-13.html')
module.exports['appointments-14'] = require('./appointments/appointments-14.html')
module.exports['appointments-15'] = require('./appointments/appointments-15.html')
module.exports['appointments-16'] = require('./appointments/appointments-16.html')
module.exports['appointments-17'] = require('./appointments/appointments-17.html')
module.exports['appointments-18'] = require('./appointments/appointments-18.html')
module.exports['appointments-19'] = require('./appointments/appointments-19.html')
module.exports['appointments-20'] = require('./appointments/appointments-20.html')
module.exports['appointments-21'] = require('./appointments/appointments-21.html')
module.exports['appointments-22'] = require('./appointments/appointments-22.html')
module.exports['appointments-23'] = require('./appointments/appointments-23.html')
module.exports['appointments-24'] = require('./appointments/appointments-24.html')
module.exports['appointments-25'] = require('./appointments/appointments-25.html')
module.exports['appointments-26'] = require('./appointments/appointments-26.html')
module.exports['appointments-27'] = require('./appointments/appointments-27.html')
module.exports['appointments-28'] = require('./appointments/appointments-28.html')
module.exports['appointments-29'] = require('./appointments/appointments-29.html')
module.exports['appointments-30'] = require('./appointments/appointments-30.html')
module.exports['appointments-31'] = require('./appointments/appointments-31.html')
